
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* ------- LIGHT THEME ------- */

body {
  font-family: 'DM Sans', sans-serif;
  line-height: 1.6;
  background-color: #ffffff;
  color: #222222;
  transition: background-color 1s ease, color 1s ease;
}

.App {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.AppMain {
  text-align: center;
}

.AppMain h1 {
  font-family: 'DM Sans', sans-serif;
  font-size: 5em;
  color: #222222;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.2em;
}

.AppMain p {
  font-family: 'DM Sans', sans-serif;
  font-size: 1em;
  color: #222222;
  text-transform: uppercase;
  font-weight: 500;
}

.AppHeader {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.ToggleButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: absolute;
  right: 0;
  top: 20px;
}

.ThemeLogo {
  fill: currentColor; /* Ensure the fill color is inherited */
}

.ToggleButton svg {
  height: 3em;
  width: 3em;
}

.invert {
  filter: invert(1);
}

.AppHeader p {
  font-family: 'DM Sans', sans-serif;
  font-size: 1em;
  color: #222222;
  font-weight: 500;
}

.AppLogo {
  height: 20vmin;
  fill: #222222;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* pointer-events: none; */
}

footer {
  text-align: center;
  font-size: 0.9em;
  color: #222222;
  margin-top: 50px;
}

/* ------- DARK THEME ------- */

body.dark {
  background-color: #222222;
  color: #E3DCED;
}

.AppLogo.dark {
  fill: #E3DCED;
}

.App.dark .AppMain h1 {
  color: #E3DCED;
}

.App.dark .AppMain p {
  color: #E3DCED;
}

.App.dark .AppHeader p {
  color: #E3DCED;
}

.App.dark footer {
  color: #E3DCED;
}

/* ------- MEDIA QUERIES ------- */

@media (prefers-reduced-motion: no-preference) {
  .AppLogo {
    animation: App-logo-spin infinite 10s linear;
  }
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media (max-width: 1200px) {
  .App {
    padding: 15px;
  }

  .AppMain h1 {
    font-size: 4em;
  }

  footer {
    font-size: 0.6em;
  }
}

/* ------- WEB BREAKPOINTS ------- */

@media (max-width: 992px) {
  .App {
    padding: 10px;
  }

  .AppMain h1 {
    font-size: 3em;
  }

  .AppMain p {
    font-size: 0.9em;
  }

  footer {
    font-size: 0.6em;
  }
}

@media (max-width: 768px) {
  .App {
    padding: 5px;
  }

  .AppMain h1 {
    font-size: 2.5em;
  }

  .AppMain p {
    font-size: 0.8em;
  }

  .AppLogo {
    height: 25vmin;
  }

  footer {
    font-size: 0.6em;
  }
}

@media (max-width: 576px) {
  .AppMain h1 {
    font-size: 2em;
  }

  .AppMain p {
    font-size: 0.7em;
  }

  .AppLogo {
    height: 30vmin;
  }

  footer {
    font-size: 0.6em;
  }
}